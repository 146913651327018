import { render, staticRenderFns } from "./AdvantagesOfTradingWarrants.vue?vue&type=template&id=ff6c8650&scoped=true&"
import script from "./AdvantagesOfTradingWarrants.js?vue&type=script&lang=js&"
export * from "./AdvantagesOfTradingWarrants.js?vue&type=script&lang=js&"
import style0 from "./AdvantagesOfTradingWarrants.vue?vue&type=style&index=0&id=ff6c8650&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff6c8650",
  null
  
)

export default component.exports